@import "@/styles/variables.scss";
@import '@/styles/mixins.scss';

.theme-light {
  --brand-primary: rgb(47, 112, 193);
  --brand-secondary: rgb(116, 97, 195);
  --brand-alternative: rgb(19, 120, 134);
  --background-site: rgb(249, 249, 249);
  --background-code: rgb(244, 244, 244);
  --text-body: rgb(54, 49, 61);
  --text-comment: rgb(99, 94, 105);
  --text-high-contrast: rgb(49, 49, 49);
  --text-medium-contrast: rgb(99, 94, 105);
  --text-low-contrast: rgb(116, 109, 118);
  --detail-high-contrast: rgb(192, 192, 192);
  --detail-medium-contrast: rgb(138, 34, 34);
  --detail-low-contrast: rgb(240, 240, 242);
  --admonition-note: rgb(46, 109, 188);
  --admonition-warning: rgb(255, 196, 9);
  --admonition-danger: rgb(220, 38, 38);
  --brand-primary-rgb-value: 47, 112, 193;
  --brand-secondary-rgb-value: 116, 97, 195;
  --brand-alternative-rgb-value: 19, 120, 134;
  --background-site-rgb-value: 249, 249, 249;
  --background-code-rgb-value: 244, 244, 244;
  --text-body-rgb-value: 54, 49, 61;
  --text-comment-rgb-value: 99, 94, 105;
  --text-high-contrast-rgb-value: 49, 49, 49;
  --text-medium-contrast-rgb-value: 99, 94, 105;
  --text-low-contrast-rgb-value: 116, 109, 118;
  --detail-high-contrast-rgb-value: 192, 192, 192;
  --detail-medium-contrast-rgb-value: 234, 234, 234;
  --detail-low-contrast-rgb-value: 240, 240, 242;
  --admonition-note-rgb-value: 46, 109, 188;
  --admonition-warning-rgb-value: 255, 196, 9;
  --admonition-danger-rgb-value: 220, 38, 38;
}
.theme-dark {
  --brand-primary: rgb(138, 180, 248);
  --brand-secondary: rgb(193, 168, 226);
  --brand-alternative: rgb(136, 186, 191);
  --background-site: rgb(0, 0, 0);
  --background-code: rgb(12, 12, 12);
  --text-body: rgb(222, 222, 222);
  --text-comment: rgb(170, 170, 170);
  --text-high-contrast: rgb(230, 230, 230);
  --text-medium-contrast: rgb(202, 202, 202);
  --text-low-contrast: rgb(170, 170, 170);
  --detail-high-contrast: rgb(101, 101, 101);
  --detail-medium-contrast: rgb(25, 25, 25);
  --detail-low-contrast: rgb(21, 21, 21);
  --admonition-note: rgb(138, 180, 248);
  --admonition-warning: rgb(253, 186, 116);
  --admonition-danger: rgb(220, 38, 38);
  --brand-primary-rgb-value: 138, 180, 248;
  --brand-secondary-rgb-value: 193, 168, 226;
  --brand-alternative-rgb-value: 136, 186, 191;
  --background-site-rgb-value: 0, 0, 0;
  --background-code-rgb-value: 12, 12, 12;
  --text-body-rgb-value: 222, 222, 222;
  --text-comment-rgb-value: 170, 170, 170;
  --text-high-contrast-rgb-value: 0, 0, 0;
  --text-medium-contrast-rgb-value: 202, 202, 202;
  --text-low-contrast-rgb-value: 170, 170, 170;
  --detail-high-contrast-rgb-value: 101, 101, 101;
  --detail-medium-contrast-rgb-value: 25, 25, 25;
  --detail-low-contrast-rgb-value: 21, 21, 21;
  --admonition-note-rgb-value: 138, 180, 248;
  --admonition-warning-rgb-value: 253, 186, 116;
  --admonition-danger-rgb-value: 220, 38, 38;
}

.embla {
  max-width: 48rem;
  position: relative;
  --slide-height: 279px;
  --slide-size: 100%;
  --slide-spacing: 4px;

  &_isNew {
    position: absolute;
    top: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-3 $spacing-5;
    z-index: 1;
    background-color: $color-green;
    color: #fff;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }

  &_relativeDate {
    position: absolute;
    bottom: 16px;
    right: 0;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    padding: $spacing-1 $spacing-3;
    z-index: 1;
    font-size: $font-size-xs;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &_bookmark {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 0;
    scale: 1;
    transition:
            scale 0.1s ease-in-out;
    animation: normal;
    font-size: 26px;
    line-height: 0;
    padding: 7px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      scale: 1.15;
    }
  }

  &_bookmarkIcon {
    stroke-width: 18;
    stroke: #fff;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.2s ease-in-out;

    &__isBookmarked {
      color: #fff;
    }
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  height: var(--slide-height);
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 372px;
  object-fit: cover;
}

.embla__slide__img {
  user-select: none;
}


@include for-screen-from-568 {
  .embla {
    --slide-size: 50%;
  }
}

@include for-screen-from-768 {
  .embla {
    max-width: 48rem;
    position: relative;
    --slide-height: 279px;
    --slide-size: 488px;
  }
}

@include for-screen-from-1024 {
  .embla__buttons {
    opacity: 0;
  }

  .embla {
    &:hover .embla__buttons {
      opacity: 1;
    }
  }
}
