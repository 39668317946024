@import '@/styles/variables.scss';

.listingCardViewGrid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__list {
    flex-direction: row;
  }

  &_body {
    padding: $spacing-4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &_info {
    margin-top: $spacing-4;
    display: flex;
    gap: $spacing-4;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &_title {
    font-size: $font-size-base;
    font-weight: 500;
  }

  &_description {
    max-height: 60px;
    min-height: 40px;
    position: relative;
    overflow: hidden;
    font-size: $font-size-s;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    color: rgb(51, 51, 51);
    line-height: 20px;

    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 20px;
      content: '';
      background: linear-gradient(to right, rgba(255, 255, 255, 0.8), #fff 40%);
    }
  }

  &_descriptionIcon {
    position: absolute;
    bottom: 2px;
    font-size: 16px;
    right: 15px;
    z-index: 5;
    line-height: 0;
  }

  &_message {
    margin-top: auto;
    padding-top: $spacing-5;
    width: 130px;
  }
}
