@import "@/styles/variables";

.badge {
  width: fit-content;
  padding: $spacing-1 $spacing-3;
  border-radius: $border-radius-ellipse;
  background-color: $color-yellow;
  color: $color-black;
  font-size: $font-size-xxs;
  font-weight: 500;
}
