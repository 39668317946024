
@import "@/styles/variables.scss";

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 3.6rem;
  height: 279px;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  align-items: center;

  &__isMapPreview,
  &__isAccountPreview {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100px;
    background-color: #fff;

    .embla__button__svg {
      background-color: unset;
      margin: 0;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;

      .embla__button__svg {
        color: $color-gray;
      }
    }
  }

  &__isListingDetailPreview {
    background-color: unset;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:disabled,
    &[disabled] {
      display: none;
    }

    .embla__button__svg {
      background-color: unset;
      border: 1px solid #fff;
      color: #fff;
      width: 40px;
      height: 40px;
      padding: 13px;

      &:hover {
        background-color: $color-grey3;
      }
    }

    &.embla__button__left {
      left: -45px;
    }

    &.embla__button__right {
      right: -45px;
    }
  }
}

.embla__button__isMapPreview,
.embla__button__isAccountPreview {
  &.embla__button__left {
    left: -40px;
    border-radius: $border-radius-base 0 0 $border-radius-base;

  }

  &.embla__button__right {
    right: -40px;
    border-radius: 0 $border-radius-base $border-radius-base 0;
  }
}

.embla__button__isAccountPreview {
  &.embla__button__left {
    box-shadow: rgba(0, 0, 0, 0.12) -10px 0px 16px;
  }

  &.embla__button__right {
    box-shadow: rgba(0, 0, 0, 0.12) 10px 0px 16px;
  }
}

.embla__button__svg {
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 50%;
  background-color: $color-gray-light;
  color: $color-gray-dark;

  &__right {
    margin-left: auto;
  }
}
