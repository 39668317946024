@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.modal {
  @include hide();

  z-index: $z-index-maximal;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;

  &__isActive {
    @include show();
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }

  &_close {
    position: absolute;
    right: $spacing-3;
    top: $spacing-3;
    font-size: 38px;
    line-height: 0;
    cursor: pointer;
    opacity: 0.6;
    scale: 1;
    animation: normal;
    transition:
      scale 0.2s ease-in-out,
      opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      scale: 1.1;
    }
  }

  &_content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 50px $spacing-4 $spacing-4;
    border-radius: $border-radius-base;
    background-color: #fff;
  }
}

@include for-screen-from-480 {
  .modal {
    &_content {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      height: auto;
    }
  }
}

@include for-screen-from-768 {
  .modal {
    &_content {
      width: auto;
    }
  }
}
